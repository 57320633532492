@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Poppins:wght@300;400;500;600;700;800&display=swap');


.searchicon{
  height: 38px;
}
.menuheight .dropdown-item{
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 8px 12px;
}
.menuheight .dropdown-item:hover{
  background-color: var(--themebtnclr);
  color: #ffffff;
}
.etimg{
  width: 21px;
  height: 21px;
  object-fit: contain;
  aspect-ratio: 3/2;
}
.comingsoon{
  height: 40vh;
}

@media screen and (max-width:575px) {
  .comingsoon{
    height: 18vh;
  }
}
@media screen and (max-width:374px) {
  .comingsoon h1{
   font-size: 19px;
  }
}





:root {
  --themebtnclr: #FFA60E;
}
.sidebarlist{
  overflow: hidden;
}
body {
  background-color: #c8c8c8;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}
body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
.apexcharts-legend{
  display: none !important;
}
.apexcharts-datalabels{
  display: none !important;
}

.f-13{
  font-size: 13px;
}
.step1 img{
  border-radius: 12px;
}
.f-14 {
  font-size: 14px;
}
.f-15 {
  font-size: 15px;
}
.fw-900 {
  font-weight: 900;
}
.fw-800 {
  font-weight: 800;
}
.fw-700 {
  font-weight: 700;
}
.fw-600 {
  font-weight: 600;
}

.header_alert__8Mnqu {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 0 20px;
  background: #f4f4f4;
  color: black;
  /* height: 35px; */
  height: 50px;

  font-size: 15px;
  opacity: 1;
  transition: .3s ease-in-out;
  /* position: relative;
    width: 6200px;
    left: calc(50% - 3100px); */
}
.gameheader .header_alert__8Mnqu{
  display: grid;
  align-items: center;
  justify-content: unset;
  padding: 2px 80px;
}
.cw{
  color: var(--themebtnclr);
  font-weight: 700;
  cursor: pointer;
  font-size: 14px;
  transition: 0.5s;
}
.cw:hover{
  color: black;
  transition: 0.5s;
}
.gameheader .wimg{
  /* filter: brightness(0.5); */
}
.sidebarlist .header_alert__8Mnqu {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 77;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.header_alert__text__uomPs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_alert__8Mnqu .header_winr__Lq3w1 {
  color: black;
  margin-right: 4px;
}

.header_alert__8Mnqu .header_address__pkb9E {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: .3s;
  cursor: pointer;
}
.me-auto{
  padding-left: 40px;
}
.wallet {
  background-color: var(--themebtnclr) !important;
  /* border-color: var(--themebtnclr) !important; */
  color: #fff !important;
  font-weight: 600;
  border-radius: 8px !important;
  border: 2px solid #d48909;
}

.wallet:hover {
  background-color: #363636 !important;
  border-color: #363636 !important;
  color: #fff !important;
}

.play {
  max-width: 140px;
  width: 100%;
  min-width: 140px;
}

.navbar-light .navbar-nav .nav-link {
  color: black !important;
  font-weight: 600;
  font-size: 16px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fff !important;
}
.referral{
  color: var(--themebtnclr);
}
.referralticket{
  background: url("../assets/referral-box.svg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 26px 20px;
}
.referralticket img{
  width: 100%;
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
  object-fit: contain;}
/* home */
.leftside {
  position: relative;
  z-index: 5;
  overflow: hidden;
}
.msgfun{
  position: fixed;
  right: 0;
  z-index: 777;
  bottom: 20%;
}
.referborder{
  border-radius: 8px !important;
  padding: 20px 18px !important;
}
.heightborder{
  height: 300px;
  max-height: 300px;
  min-height: 300px;
}
.modalborder{
  border: none !important;
  padding: unset !important;
  background: unset !important;
}
.btn-outline-warning{
  border: 2px solid var(--themebtnclr) !important;
  color: black !important;
  font-size: 13px;
  border-radius: 6PX !important;
  font-weight: 500;
}
.btn-outline-warning:hover{
  color: #fff !important;
  background-color: var(--themebtnclr) !important;
}
.groupicon {
  position: absolute;
  top: 68%;
  max-width: 70%;
  left: 20%;
}

.allcoin {
  margin-top: -25%;
}

.allhome {
  position: relative;
  overflow: hidden;
}
.css-d7l1ni-option {
  background-color: #ffa60e !important;
  color: white !important;
  border-radius: 8px;
}
.css-tr4s17-option {
  background-color: #ffa60e !important;
  border-radius: 8px;
}
.css-d7l1ni-option::after{
 content: "";
 background: url("../assets/arrow.png");
}
.social a {
  margin-left: 12px;
}
.css-13cymwt-control {
  border-color: hsl(0deg 0% 95.69%) !important;
}
/* .footer .social a img {
  filter: brightness(0.5);
} */

.social a:hover {
  filter: drop-shadow(2px 4px 6px #ffa60e);
}

.bottomcoin {
  position: fixed;
  width: 100%;
  left: 9%;
  margin: auto;
  max-width: 82%;
  min-width: 82%;
  bottom: -4%;
  z-index: 7;
}
.offcanvas {
  background-color: #f4f4f4 !important;
}
.navbar-toggler {
  background-color: #ffffff !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.walletlist {
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transform: 0.5s;
}

.walletlist:hover {
  background-color: var(--themebtnclr);
  border: 1px solid var(--themebtnclr);
  color: #fff;
}

.walletlist img {
  width: 40px;
  height: 40px;
}

.sidebarlist .header2 {
  display: none;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 80px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  /* overflow: auto; */
  /* overflow-y: auto; */
  z-index: 999;
  top: 7% !important;
}

.sidebar span {
  margin-top: 6px;
}

.sidebar a .hoverimg {
  display: none;
  text-align: center;
  /* margin-left: 12px; */
}

.sidebar a:hover .hoverimg {
  display: block;
}

.sidebar a:hover .normalimg {
  display: none;
}

.sidebar a.active .normalimg {
  display: none;
}

.sidebar a.active .hoverimg {
  display: block;
}

.sidebar a img {
  width: 29px;
  height: 29px;
  object-fit: contain;
  margin: auto;
}
.sidebar .logos img {
  width: unset;
  height: unset;
  object-fit: unset;
  margin: auto;
  filter: drop-shadow(0px 1px 4px gray);
}
.sidebar a.active {
  color: var(--themebtnclr);
  border-right: 2px solid var(--themebtnclr);
}

.sidebar a {
  display: block;
  color: black;
  font-weight: 700;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  margin-top: 15px;
  font-size: 14px;
  border-right: 2px solid #f4f4f4
}

.sidebar .logos{
  padding: 4px !important;
}
.sidebar .dropdown-content a {
  margin-top: 0px;
  border: none;
  text-align: left !important;
}

.sidebar .dropdown a:hover {
  border: none;
}

.sidebar a:hover {
  color: var(--themebtnclr);
  padding: 10px;
  text-align: center;
  text-decoration: none;
  border-right: 2px solid var(--themebtnclr);
  /* filter: drop-shadow(2px 4px 10px #ffa60e); */
}
.sidebar .logos:hover{
  border: none !important;
}
.sidebar .active {
  color: var(--themebtnclr);
}

.content {
  margin-left: 80px;
}

.sidelogo {
  padding: 10px;
  max-width: 80px;
  margin: auto;
}


.headingz {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-success {
  color: #fff;
  font-size: 13px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 9%);
  border-radius: 8px !important;
}
.walletdrop .dropdown-toggle::after {
  display: inline-block;
  margin-left: 10px;
  vertical-align: 0.2em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
}
.walletdrop .ptoken {
  /* color: black !important; */
    font-weight: 500;
}
.walletdrop .wimg {
  filter: unset;
}
.walletdrop a{ 
  color: black;
  text-decoration: none;
  font-size: 15px;
  margin-bottom: 12px;
  display: block;
  transition: 0.5s;
}
.form-check-input:checked {
  background-color: var(--themebtnclr);
  border-color: var(--themebtnclr);
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(255 166 14 / 24%) !important;
}
.walletdrop a:hover{
  color: var(--themebtnclr);
  transition: 0.5s;
}
.contest {
  background-color: #036837 !important;
  border-color: #036837 !important;
}

.stakebtn {
  background-color: #625AEA !important;
  border-color: #625AEA !important;
}

.earnbtn {
  background-color: var(--themebtnclr) !important;
  /* border-color: var(--themebtnclr) !important; */
  border: 2px solid #d48909;
}

.btn-success:hover ,.btn-success:focus{
  background-color: #363636 !important;
  border-color: #363636 !important;
}

.dashboard {
  /* border: 1px solid #e3e3e3;
  background-color: #e3e3e3; */
  background: url("../assets/dashbackimg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 17px 9px 17px 9px;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 2%);
  border: 2px solid #d9d9d9;
  position: relative;
  overflow: hidden;
  z-index: 6;
}
.tri{
  position: absolute;
  top: 50%;
  left: -2%;
  max-width: 30px;
  z-index: -1;
}

.dashboard:hover {
  /* box-shadow: 0 3px 10px rgb(255 166 14 / 42%); */
}

.dashboard p {
  font-size: 14px;
  margin-bottom: 0px !important;
  margin-top: 8px;
}
/* .dashboard p:nth-child(1) {
  color: #474d59;
} */

.dropdown {
  position: relative;
  /* display: inline-block; */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 8px 10px;
  z-index: 1;
  left: 81px;
  top: 0;
  border-radius: 8px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropsideimg {
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-right: 8px !important;
}

.owlcarousel .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #5B606A;
}

.owlcarousel .owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 8px;
}

.owlcarousel .item {
  background: url("../assets/backimg.png");
  background-repeat: no-repeat;
  padding: 15px 20px;
  border-radius: 8px;
  background-color: #e3e3e3;
  background-position: right;
 
}

.btn-outline-secondary {
  color: #363636 !important;
  border-color: #5b606a !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 14px;
}

.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: #5b606a !important;
  border-color: #5b606a !important;
}

.clr {
  color: #95989d;
}

.allbtns {
  text-align: end;
}

.btn-light {
  color: #363636 !important;
  background-color: #E3E3E3 !important;
  border-color: #E3E3E3 !important;
  font-size: 14px;
  border-radius: 6px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.howtoplay .btn-light{
  border: 1px solid #1715187a !important;
}
.btn-light:hover {
  color: #fff !important;
  background-color: #363636 !important;
  border-color: #363636 !important;
}

.cardss .card {
  border-radius: 6px;
  transition: all .3s ease;
}

.cardss a {
  text-decoration: none;
}

.cardss .card:hover {
  transform: translateY(-5px);
}

.cardss .card-img {
  position: relative;
  overflow: hidden;
}

hr {
  border-color: black;
  opacity: 0.8 !important;
}

hr:not([size]) {
  height: 2px !important;
}

.cardss img {
  border-radius: 6px 6px 0px 0px;
  height: 150px;
  max-height: 150px;
  min-height: 150px;
  width: 100%;
  object-fit: cover;
  transition: transform .5s ease;
}

.cardss img:hover {
  transform: scale(1.1);
}
.volumeimg{
  margin-top: -2px;
}
.cardss .card-body {
  padding: 10px 10px;
  background-color: #363636;
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  margin-top: -1px;
}

.footer ul {
  padding-left: 0px !important;
}

.footer li {
  list-style-type: none;
  margin-top: 8px;
}

.footer li a {
  color: black;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}

.footer li a:hover {
  color: var(--themebtnclr);
}

.foot-p {
  font-size: 14px;
}

.modal-content {
  background-color: #f1f1f1 !important;
  border-radius: 8px;
}
.gameborder {
  border: 1px solid #e3e3e3;
  background-color: #e3e3e3;
  padding: 0px 14px 0px 14px;
  border-radius: 10px 10px 0px 0px;
}
.play{
  cursor: pointer;
  transition: 0.5s;
}
.play:hover{
  color: black;
  transition: 0.5s;
}
.table>thead {
  border-bottom: 2px solid #e3e3e3 !important;
  background-color: #e3e3e3;
  font-size: 15px;
}

.table>:not(caption)>*>* {
  padding: 0.8rem 0.5rem !important;
}

tbody tr td:last-child {
  color: green;
}

tbody {
  font-size: 15px;
  font-weight: 600;
}

.table tbody tr {
  border-bottom: 1px solid #1D2738;
}

tbody img {
  margin-top: -4px;
}

.alltable {
  overflow: auto;
}

.alltable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.alltable::-webkit-scrollbar {
  width: 4px;
  height: 7px;
  background-color: #F5F5F5;
}

.alltable::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 4px;
}


.backsideimg {
  background: url("../assets/coinback.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.gameborder .form-control {
  font-weight: 600;
  color: black;
  background-color: transparent;
  border: 2px solid #a2a2a2db;
  border-radius: 6px;

}
.input-group .form-control{
  /* border-right-color: transparent; */
}
.gameborder .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -18px;
}
.gameborder .input-group-text {
    background-color: transparent;
    border: 2px solid #ababab;
    border-radius: 0.25rem;
    border-left-color: transparent;
    position: relative;
}
.nochange{
  position: absolute;
  right: 55px;
  top: 8px;
}
.nochange1{
  position: absolute;
  left: 14px !important;
  top: 8px;
  font-weight: 600;
}
.uparrow{
  position: absolute;
  top: 2px;
  left: -4px;
  font-size: 16px;
  color: black;
  cursor: pointer;
}
.downarrow{
  position: absolute;
  bottom: 2px;
  left: -4px;
  font-size: 16px;
  color: black;
  cursor: pointer;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.range1 .rangeslider {
  position: absolute;
  bottom: 2px;
    z-index: 7;
    width: 100%;
}
.btn-dark {
  color: #fff;
  background-color: #344158 !important;
  border-color: #344158 !important;
  font-size: 12px !important;
  padding: 2px 10px !important;
}
.btn-dark:hover{
  background-color: black !important;
  border-color: black !important;
}
.rangeslider {
  background: #ababab !important;
  margin: -2px 0 !important;
}
.rangeslider-horizontal {
  height: 3.5px !important;
  border-radius: 10px !important;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 20px !important;
  height: 20px !important;
  border-radius: 6px !important;
}
.rangeslider-horizontal .rangeslider__handle:after {
  width: 12px !important;
  height: 12px !important;
  top: 3px !important;
  left: 3px !important;
  background-color: #ffff !important;
}
.rangeslider .rangeslider__handle {
  background: #a2a2a2 !important;
  border: 1px solid #a2a2a2 !important;
  box-shadow: unset !important;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: var(--themebtnclr) !important;
}
.borders{
  border: 1px solid #a2a2a2;
    background-color: #a2a2a26b;
    border-radius: 6px;
    padding: 4px 8px;
    color: black;
}
.que{
  color: black;
  font-size: 15px;
  margin-top: -3px;
}
summary {
  display: block;
  position: relative;
}
summary::before{
  content: "";
  border: 1px solid #8b8686;
  position: absolute;
  top: 12px;
  width: 100%;
  max-width: 40%;
  right: 0%;
}
/* the rest is just for esthetics */

summary::after {
  margin-left: 1ch;
  display: inline-block;
  content: '▶️';
  transition: 0.2s;
    transform: rotate(360deg);
   
}
details[open] summary::after {
  transform: rotate(90deg);
}
.walletss{
  font-size: 15px !important;
  font-weight: 600 !important;
}
.dayss{
  color: #4c4c4c !important;
}
.bottomborder{
  background-color: rgb(161 161 161);
  border: 1px solid rgb(161 161 161);
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
}
.dblack{
  color: black;
  font-weight: 700;
}
.lgray{
  color: #8b8b8b;
  font-weight: 700;
  font-size: 13px;
}
.btn-close:focus {
  box-shadow: 0 0 0 0.25rem rgb(255 166 14 / 12%) !important;
}
.step1 .firstbtn{
  background-color: black !important;
}
.step1 .firstbtn svg{
  color: #fff;
}
.css-1dimb5e-singleValue {
  font-size: 13px;
  font-weight: 700;
}
.css-1jqq78o-placeholder,.css-10wo9uf-option {
font-size: 13px;
}
/* .css-d7l1ni-option {
  font-size: 13px !important;
} */
.css-1nmdiq5-menu {
  max-width: 150px;
  min-width: 150px;
  border-radius: 8px !important;
}
.css-1n6sfyn-MenuList {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 8px;
  padding-right: 8px;
}
.walletdrop .dropdown-menu{
  padding: 0.8rem 0.8rem;
  min-width: 14rem;
  border-radius: 10px;
}
.btn-success:focus {
  box-shadow: 0 0 0 0.25rem rgb(60 153 110 / 0%) !important;
}
.tokenborder{
  border: 1px solid #858383;
  padding: 6px;
  border-radius: 6px;
}
.stakeborder{
  padding: 20px !important;
  border-radius: 10px !important;
}
.stakeborder .nochange1 {
  left: 30px !important;
}
[aria-label="tail-spin-loading"]{
  justify-content: center;
}
.stakeborder .btn-outline-secondary{
  border: 2px solid #a2a2a2db !important;
  text-transform: unset !important;
  font-size: 17px !important;
}
.stakeborder .rangeslider .rangeslider__handle {
  background: var(--themebtnclr) !important;
  border: 1px solid var(--themebtnclr) !important;
}
.stakeborder .rangeslider-horizontal .rangeslider__handle {
  width: 7px !important;
}
.stakeborder .rangeslider-horizontal .rangeslider__handle:after {
 display: none !important;
}
.form-control:focus {
  box-shadow: unset;
  background-color: transparent !important;
}
.rewardimg{
  position: relative;
  /* overflow: hidden; */
}
.coingold{
  position: absolute;
    left: 2px;
    top: -6px;
    width: 100%;
}
.bottomborder .rangeslider {
  background: #e3e3e3 !important;
}
.bottomborder .rangeslider-horizontal {
  height: 5px !important;
}
.bottomborder .rangeslider .rangeslider__handle {
  background: #e3e3e3 !important;
}
.nthno {
  /* color: rgb(104 117 140);
  font-weight: 700; */
  color: #363636;
  font-weight: 600;
}
.stakeborder .borders{
    border-radius: 12px;
}
.stakeborder{
  height: 460px;
  min-height: 460px;
  max-height: 460px;
  position: relative;
  z-index: 6;
}
.wlpdrop{
  background-color: #c8c8c8;
  border-radius: 20px;
  padding: 6px 20px;
  font-size: 15px;
  font-weight: 600;
}
.piecharts .dropdown-menu {
  background-color: #f4f4f4;
  border: 1px solid rgb(0 0 0 / 5%);
  border-radius: 10px;
  padding: 10px;
}
.piecharts ul {
  padding-left: 0px !important;
  list-style-type: none;
  margin-top: 6%;
}
.piecharts ul li {
 display: flex;
 justify-content: space-between;
 border-bottom: 1px solid black;
}
.rewardbg{
  background: url("../assets/rewardsbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 14px 16px;
}
.wlpb{
  background-color: white;
  border: 1px solid #ffffff;
  padding: 4px 10px;
  border-radius: 10px;
}
.css-tj5bde-Svg {
  fill: #5b5a5a !important;
}
.liness{
  /* max-width: 100% !important;
  width: unset !important; */
  max-width: 42% !important;
  width: 100% !important;
  margin-top: -14px;
}
.fees{
  color: rgb(104 117 140);
}
.stakeborder .nav-pills{
  background: #c8c8c8;
  border-radius: 20px;
  width: fit-content;
}
.earntab{
  background-color: rgb(200 200 200);
  padding: 14px 18px;
}
.earntab .css-13cymwt-control {
  border-radius: 10px !important;
}
.earntab .form-control{
    background-color: white;
    border: 1px solid #ffffff;
    height: 34px;
    max-width: 50%;
    text-align: end;
    border-radius: 10px;
}
.earntab .swapbtn .form-control{
  background-color: unset;
    border: unset;
    height: unset;
    max-width: unset;
    text-align: unset;
    border-radius: unset;
}
.swapbtn .input-group{
  border: 1px solid gray;
    border-radius: 6px;
}
.swapbtn .input-group-text{
  border: 2px solid transparent;
}

.stakeborder .nav-pills .nav-link {
  font-size: 15px;
  color: black !important;
  font-weight: 600;
  padding: 10px 20px;
}
.stakeborder .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #ffff !important;
  background-color: var(--themebtnclr);
  border-radius: 20px;
}
.piecharts ul p:nth-child(1){
  color: #68758c !important;
  font-weight: 700;
  font-size: 15px;
}
.piecharts ul p:nth-child(2){
  font-weight: 600;
  font-size: 15px;
}
.piecharts .dropdown-item{
  font-size: 13px;
  font-weight: 600;
}
.piecharts .dropdown-item:hover {
  color: #fff;
  background-color: var(--themebtnclr);
  border-radius: 6px;
}
.btn:focus {
  box-shadow: unset !important;
}
.walletsss{
  font-weight: 600 !important;
  font-size: 16px !important;
}
/* .stakeborder::after{
content: "";
background: url("../assets/vinmeen.png");
background-repeat: no-repeat;
position: absolute;
top: 0;
} */
/* .createdborder{
  height: unset !important;
  min-height: unset !important;
  max-height: unset !important;
} */
.createdborder thead {
  color: #8b8b8b;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.createdborder .alltable{
  height: 240px;
  min-height: 240px;
  max-height: 240px;
  overflow: auto;
  padding-right: 10px;
}
.createdborder .vestingtable {
  height: unset;
  min-height: unset;
}
.offcanvas-start {
  width: 310px !important; 
}
.referralheading{
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 23px;
}
.ref_table thead {
  background: #c3c2c2;
}
.reftable thead{
  background: #e3e3e3;
}
.CircularProgressbar-trail {
  stroke: #ababab !important;
  /* background: url("../assets/storkepng.png") !important; */
}
.CircularProgressbar-path {
  stroke: rgb(108 188 53) !important;
}
.greentext{
  color: rgb(72 164 9);
  font-weight: 700;
}
.nclr{
  color: #fff;
}
.allnos{
  font-weight: 700;
  font-size: 15px;
}
.circlelast{
  border: 1px solid var(--themebtnclr);
  background-color: var(--themebtnclr);
  padding: 8px 6px;
  border-radius: 8px;
  font-size: 13px;
  text-align: center;
  color: #fff;
  font-weight: 500;
}
.circlelist{
  /* position: relative; */
}
.circular-progress_tick{
background-color: rgb(108 188 53);
position: absolute;
left: 50%;
top: -5px;
transform: translateX(-50%);
border-radius: 50%;
padding: 7px 6px;
z-index: 2;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.circular-progress_tick svg{
  display: block;
}

.rank{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 12px;
  width: 45px;
  height: 45px;
  background: #363636;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  z-index: 2;
}
.refalltable{
  height: 180px;
  padding-right: 10px;
  min-height: 180px;
  max-height: 180px;
}
.notactive{
  padding: 4px 0px;
  font-weight: 600;
  font-size: 12px;
}
.weekly{
  font-size: 45px;
}
.upto{
  font-size: 42px;
}
.gen,.us{
  font-size: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1951px) {

  .homemenumobile{
    display: none;
  }
  .mobileplay{
    display: none;
  }
  .mobileview{
    display: none;
  }
  .sidebar {
    top: 50px !important;
  }
}

@media only screen and (min-width: 1952px) 
{

  .homemenumobile{
    display: none;
  }
  .mobileplay{
    display: none;
  }
  .mobileview{
    display: none;
  }
  .sidebar {
    top: 50px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .circlelast {
    font-size: 10px;
  }
  .weekly {
    font-size: 35px;
}
}
@media only screen and (min-width: 992px) and (max-width: 1090px) {
.gameheader .header_alert__8Mnqu {
  padding: 2px 30px;
}

}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gen, .us {
    font-size: 18px;
}
  .weekly {
    font-size: 26px;
}
.upto {
  font-size: 26px;
}
  .earntab .css-13cymwt-control {
    width: 100%;
    max-width: 75%;
}
  .referralticket {
    height: 120px;
    min-height: 120px;
    max-height: 120px;
}
.referralheading {
  font-size: 15px;
}
  label{
    font-size: 14px !important;
  }
  .contribution h6 {
    font-size: 12px;
  }
}


@media only screen and (max-width: 991px) {
 
  .listout{
    display: block;
  }
  .stakeborder {
    height: unset;
    min-height: unset;
    max-height: unset;
}
  .navmobile .nav-link {
      color: black;
      font-weight: 600;
  }
  .bottomcoin {
    bottom: 0%;
}
  .mobileburger{
    max-width: 40px;
    height: 40px;
    min-width: 40px;
    max-height: 40px;
    width: 100%;
  }
  .homemenudesktop{
    display: none;
  }
  /* .ptoken {
    font-size: 20px;
  }
  .mobileplay {
    font-size: 20px;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 20px;
    margin-bottom: 6px;
} */
  .mobilenavs{
    text-align: center;
    margin-top: 2%;
  }
  .allcontest img{
    width: 20px;
  }
  .allcontest .btn-success {
    font-size: 13px;
}
  .wimg{
    /* filter: brightness(0.5); */
  }
  .desktopview{
    display: none;
  }
  .css-b62m3t-container {
    width: 100%;
}
}

@media only screen and (max-width: 900px) {
  .sidebar {
    top: 85px !important;
  }
  .header_alert__8Mnqu {
    /* margin-top: 70px; */
    height: 85px;
    text-align: center;
  }
  .header_alert__text__uomPs {
    flex-direction: column;
    gap: 5px;
  }

  .content {
    margin-left: 80px;
    margin-top: 8%;
  }

  .dropdown-content {
    height: 300px;
    max-height: 300px;
    min-height: 300px;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 767px) {
  .liness{
    display: none !important;
  }
  .sidebar {
    top: 50px !important;
  }
.gameheader .header_alert__8Mnqu{
  padding: 2px 20px;
}
/* .sidebar{
  top: 15% !important;
} */
.textshort {
  font-size: 12px;
}
}

@media only screen and (max-width: 640px) {
  .allbtns {
    text-align: left;
  }
  .textshort {
    font-size: 10px;
}
  .allbtns .mx-2 {
    margin-left: 0px !important;
  }

  .header_alert__text__uomPs {
    width: 100%;
    max-width: 320px;
  }

  /* .sidebar {
    top: 14% !important;
  } */
 .smart{
  font-size: 13px !important;
 }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .weekly {
    font-size: 36px;
}
  .earntab .css-13cymwt-control {
    width: 100%;
    max-width: 75%;
}
  .coingold {
    top: -16px;
  }
  .circlelast {
    font-size: 11px;
}
}



@media only screen and (max-width: 575px) {
  .rewardbg{
    background: #c8c8c8 !important;
    border-radius: 10px;
  }
  .howtoplay .f-14{
    font-size: 11px;
  }
  .gen,.us {
    font-size: 17px;
  }
  .weekly{
    font-size: 28px;
  }
  .upto{
    font-size: 28px;
  }
  .tri{
    display: none;
  }
  .dashboard p {
    font-size: 13px;
}
  .sidebar {
    top: 85px !important;
  }
  .earntab .justfun{
    display: unset !important;
  }
  .earntab .form-control {
    max-width: unset;
    margin-top: 10px;
}
  .stakeborder {
    padding: 20px 12px !important;
}
.stakeborder .nav-pills .nav-link {
  font-size: 10px;
  padding: 10px 10px;
}
  .textshort{
    font-size: 13px;
  }
  .piecharts ul p:nth-child(1) {
    font-size: 13px;
}
.piecharts ul p:nth-child(2) {
  font-size: 13px;
}
  /* .sidebar {
    top: 15% !important;
} */
  .owlcarousel .item {
    background: unset;
    background-color: #e3e3e3;
}
  .circlelast {
    font-size: 11px;
}
  .heightborder {
    height: unset;
    max-height: unset;
    min-height: unset;
}
  .walletss {
    font-size: 15px !important;
}
 .walletsss {
  font-size: 13px !important;
}
  .gameheader .header_alert__8Mnqu{
    padding: 2px 8px;
  }
  .bottomborder {
    padding: 10px 5px;
}
  .play,.dicename{
    font-size: 12px;
  }
  .Multi{
    font-size: 12px;
  }
  .styles_dice {
    grid-gap: 9px !important;
    gap: 9px !important;
}
.dice_box {
  width: 58px !important;
  height: 58px !important;
}
  .bet {
    font-size: 13px;
  }
  .footer .row {
    text-align: center;
  }
  .table>thead {
    font-size: 13px;
  }
  tbody {
    font-size: 13px;
  }
  .btn-light {
    font-size: 12px;
}
}

@media only screen and (max-width: 400px) {
  .header_alert__8Mnqu {
    font-size: 10px;
  }
}

.table_gme th:first-child,.table_gme td:first-child
{
  min-width: 100px;
}

@keyframes rotate-bg {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.borders_full_hei_show
{
  align-items: center;
}
.borders_full_hei_show p
{
  word-break: break-all;
}
 
.pos_stargery_card
{
  border: 1px solid #a2a2a2;
    background-color: #a2a2a26b;
    border-radius: 6px;
    padding: 4px 8px;
    color: black;
    max-width: 250px;
}

.badge_grn
{
  background-color: #2b6947;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 5px;
}

.badge_rd
{
  background-color: #933364;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 5px;
}

.intimate_card_green
{
  background-color:#2c6749d4;
  padding: 5px 10px 0px 10px;
  border-radius: 10px;
  color:#fff;
  font-size: 12px;
}
.intimate_card_red
{
  background-color:#933364ba;
  padding: 5px 10px;
  border-radius: 10px;
  color:#fff;
  font-size: 12px;

}

.img_cin_stra
{
  max-width: 25px;
  max-height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  margin-left: 10px;
}
.text-right
{
  text-align: right;
}

.prog_bar_hide {
  width: 100%;
  background-color: grey;
  margin-top: 3px;
}

.myBar {
  width: 100%;
  height: 5px;
  background-color: #ffa60e;
}
.hide_ele_div
{
  transform: translateX(-200%);
  transition: all 0.5s ease-in;
  
}
.btn_tooltip_othng
{
  background-color: transparent !important;
  border:none !important;
}
.flex_rto_div
{
  display: flex;
  align-items: center;
}
@media only screen and (max-width:575px)
{
  .border_div_wrp
  {
  flex-direction: column;
  text-align: left !important;
  align-items: flex-start !important;
  }
}



@media only screen and (min-width:768px) and (max-width:1499px)
{
  .border_div_wrp
  {
  flex-direction: column;
  text-align: left !important;
  align-items: flex-start !important;
  }
}
.border_div_wrp_txt
{
  word-break: break-all;
}
@media only screen and (max-width:767px)
{
  .pos_stargery_card
  {
    margin: 20px auto;
  }
}
.pos_stargery_card
{
  margin-top: 20px;
  margin-bottom: 20px;
}
.spin_rollbtn:disabled{
  opacity: .8;
}

.walletss_sm.walletss
{
  font-size: 13px !important;
}

.gametable_width th:first-child,
.gametable_width td:first-child
{
  min-width: 120px;
}
.td_with_img
{
  display: flex;
  align-items: center;

}
.td_with_img img
{
  margin-left: 5px;
  margin-top: 0px !important;
}

.gametable_width th:nth-child(2),
.gametable_width td:nth-child(2)
{
  min-width: 200px;
}

.gametable_width th:nth-child(4),
.gametable_width td:nth-child(4)
{
  min-width: 150px;
}
.gametable_width th:nth-child(5),
.gametable_width td:nth-child(5)
{
  min-width: 200px;
}


.gametable_width th:last-child,
.gametable_width td:last-child
{
  min-width: 200px;
}
