body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.interborder{
  border: 1px solid #fff;
  background: #fff;
  padding: 6px 12px; 
  border-radius: 8px;
}
.btop {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.swapbtn .btn-success{
  color: #000 !important;
  font-weight: 600 !important;
  background-color: #fff;
  border-color: #fff;
  font-size: 14px;
}
.swapbtn .btn-success:hover {
  background-color: #FFF !important;
  border-color: #FFF !important;
}
.swapbtn  .btn-success:focus {
  background-color: #FFF !important;
  border-color: #FFF !important;
}
.swapbtn .dropdown-menu{
  border-radius: 8px;
  padding: 14px;
}
.swapbtn .show>.btn-success.dropdown-toggle {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.swapbtn .dropdown-item{
  border-radius: 8px;
  margin-bottom: 8px;
}
.swapbtn .dropdown-item:focus, .swapbtn  .dropdown-item:hover{
  color: #fff !important;
  background-color: #FFA60E;
}
.no_item_text
{
  color:#454141 !important;
}
.right_menu_custom
{
  right: 0px;
  left: unset;
}